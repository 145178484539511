import { api } from "../utils/Api/apiClient";

const GetOrderDetail = async (id) => {
  const requestURL = `/api/abacate/v1/orders/list/${id}`;
  return await api.get(requestURL);
};

const GetOrders = async (
  page,
  text = "",
  listFilters,
  ordField = "order_date",
  ordSort = "desc"
) => {
  const status = listFilters.toString();
  const requestURL = `/api/abacate/v1/orders/list/`;
  return await api.get(requestURL, {
    params: {
      page,
      term: text,
      statusFilter: status,
      sortBy: ordField,
      sortDirection: ordSort,
    },
  });
};

const GetInitialOrders = async (
  page,
  text = "",
  listFilters,
  ordField = "order_date",
  ordSort = "desc"
) => {
  const status = listFilters.toString();
  const requestURL = `/api/abacate/v2/orders/list/`;
  return await api.get(requestURL, {
    params: {
      page,
      term: text,
      statusFilter: status,
      sortBy: ordField,
      sortDirection: ordSort,
    },
  });
};

const OrderReschedule = async (
  id,
  scheduled_date,
  scheduled_time_from,
  scheduled_time_to,
  reason_id,
  observation
) => {
  const url = `/api/orders/v1/order/${id}/reschedule`;
  return await api.patch(url, {
    scheduled_date,
    scheduled_time_from,
    scheduled_time_to,
    reason_id,
    observation,
  });
};

const OrderUpdateStatus = async (id, reasonId, observation, status) => {
  const url = `/api/orders/v1/order/${id}/status`;
  return await api.patch(url, {
    status: status,
    reason_id: reasonId,
    observation: observation,
  });
};

const getReasonReschedule = async () => {
  const url = `/api/orders/v1/reschedule/reason`;
  return await api.get(url);
};

const getReasonOrderCanceled = async () => {
  const url = `/api/orders/v1/order/reason`;
  return await api.get(url);
};

const createBreak = async (data) => {
  const url = `/api/complaints/v1/complaint`;
  return await api.post(url, data);
};

const editBreak = async (data, complaintId) => {
  const url = `/api/complaints/v1/complaint/${complaintId}`;
  return await api.patch(url, data);
};

const createBreakSolution = async (id, data) => {
  const url = `/api/complaints/v1/complaint/${id}/solution`;
  return await api.post(url, data);
};

const updateSolution = async (complaintId, data) => {
  const url = `/api/complaints/v1/complaint/${complaintId}/changestep`;
  return await api.patch(url, data);
};

const getComplaints = async ({
  step,
  dateFrom,
  dateTo,
  orderId,
  origin,
  term,
  filterStatus,
  filterReason,
  filterResponsible,
  page,
  sort,
  originReport,
  statusId,
}) => {
  const url = `/api/complaints/v1/complaint`;
  return await api.get(url, {
    params: {
      step,
      date_from: dateFrom,
      date_to: dateTo,
      order_id: orderId,
      origin,
      term,
      filter_status: filterStatus,
      filter_reason: filterReason,
      filter_responsible: filterResponsible,
      page,
      sort,
      origin_report: originReport,
      status_id: statusId,
    },
  });
};

const getComplaintsDetail = async (complaintId) => {
  const url = `/api/complaints/v1/complaint/${complaintId}`;
  return await api.get(url);
};

const getItemComplaintValue = async ({ orderId, sku, complaintQuantity }) => {
  const url = `/api/complaints/v1/complaint/value`;
  return await api.get(url, {
    params: {
      order_id: orderId,
      sku,
      complaint_quantity: complaintQuantity,
    },
  });
};

const getItemsComplaintValues = async (data) => {
  const url = `/api/complaints/v2/complaint/value`;
  return await api.post(url, data);
};

const getReasonList = async () => {
  const url = `/api/complaints/v1/reason`;
  return await api.get(url);
};

const getResponsibleReasonList = async () => {
  const url = `/api/complaints/v1/responsible`;
  return await api.get(url);
};

const updateItemResponsible = async (data) => {
  const url = `/api/complaints/v1/classification`;
  return await api.post(url, data);
};

const deleteSolution = async (id) => {
  const url = `/api/complaints/v1/complaint/${id}/solution`;
  return await api.delete(url);
};

const deleteBreak = async (id) => {
  const url = `/api/complaints/v1/complaint/${id}`;
  return await api.delete(url);
};

const asaasRefund = async ({ orderId, billingType, value, action }) => {
  const url = `/api/abacate/v1/asaas/bill/${orderId}`;
  return await api.post(url, {
    billing_type: billingType,
    action,
    value,
  });
};

const getOriginReportList = async () => {
  const url = `/api/complaints/v1/origin_report`;
  return await api.get(url);
};

const getFarmersXlsx = async ({
  statusId,
  dateFrom,
  dateTo,
  exportType,
  originReport,
  sort,
}) => {
  const url = `/api/abacate/v1/farmers/excel`;
  return await api.get(url, {
    params: {
      status_id: statusId,
      date_from: dateFrom,
      date_to: dateTo,
      export: exportType,
      origin_report: originReport,
      sort,
    },
    responseType: "blob",
  });
};

const getAnalysisList = async ({
  deliveryDateFrom,
  deliveryDateTo,
  q,
  orderBy,
  sort,
}) => {
  const url = `/api/complaints/v1/complaint/analysis`;
  return await api.get(url, {
    params: {
      deliveryDateFrom,
      deliveryDateTo,
      q,
      orderBy,
      sort,
    },
  });
};

const getAnalysisDetail = async ({ itemId }) => {
  const url = `/api/complaints/v1/complaint/analysis/${itemId}`;
  return await api.get(url);
};

const getProductsBase = async () => {
  const url = `/api/products/v1/product-base`;
  return await api.get(url, {
    params: {
      pageSize: 1000,
      orderBy: "name",
      status: "active",
    },
  });
};

const getNewComplaints = async ({
  statusId,
  origin,
  originReport,
  keyAccount,
  page,
}) => {
  const url = `/api/complaints/v1/`;
  return await api.get(url, {
    params: {
      statusId,
      origin,
      originReport,
      keyAccount,
      page,
    },
  });
};

const getNewComplaintDetail = async ({ complaintId }) => {
  const url = `/api/complaints/v1/${complaintId}`;
  return await api.get(url);
};

const OrderServices = {
  GetOrderDetail,
  GetOrders,
  GetInitialOrders,
  OrderReschedule,
  createBreak,
  getReasonList,
  createBreakSolution,
  getComplaints,
  updateSolution,
  editBreak,
  deleteBreak,
  getReasonReschedule,
  OrderUpdateStatus,
  deleteSolution,
  getResponsibleReasonList,
  updateItemResponsible,
  getComplaintsDetail,
  getReasonOrderCanceled,
  getItemComplaintValue,
  getItemsComplaintValues,
  asaasRefund,
  getOriginReportList,
  getFarmersXlsx,
  getAnalysisList,
  getAnalysisDetail,
  getProductsBase,
  getNewComplaints,
  getNewComplaintDetail,
};

export default OrderServices;
